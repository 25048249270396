import React, { useEffect, useState } from 'react';
import { RED } from '@constants/colors';
import {
  Box,
  Image,
  VStack,
  HStack,
  Container,
  Center,
  Stack,
  Text,
} from '@chakra-ui/react';
import CustomButton from '@components/Button';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NewProductProps } from '@customTypes/homepage';
import { useNavigate } from 'react-router-dom';
import fetchRequest from '@utils/fetcher';

import '@routes/Service/styles/index.css';

import {
  HOMEPAGE_TITLE,
  HOMEPAGE_DESC,
  PRODUCT_TITLE,
  TESTIMONIAL_TITLE,
  BANNER_TITLE,
  BANNER_DESC,
  SECTION_TITLE,
} from '@constants/texts';
import imgtest from '@assets/test img.png';
import WEB_CSR from '@assets/service/Thumbnail Web CSR.png'


const NewProduct = () => {
  const [newProductData, setnewProductlData] = useState<NewProductProps[]>([]);
  const navigate = useNavigate();


 

  const settings = {
    dots: true,
    arrows: true,
    
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getnewProductData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'homepage' }).then(
        (response) => {
          setnewProductlData(response.newProduct);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getnewProductData();
  }, []);

  return (
  <Slider {...settings} adaptiveHeight={true}>


{/* <Center>
          <Box
           // key={index}
            paddingX={{ lg: '50px', sm: '30px', base: '10px' }}
            paddingTop={{ lg: '20px', md: '20px', base: '15px' }}
            paddingBottom={{ lg: '50px', md: '30px', base: '20px' }}
          >
            <HStack
              w={{
                xl: '1200px',
                lg: '900px',
                md: '700px',
                sm: '360px',
                base: '360px',
              }}
              h={{ lg: '470px', md: '430px', base: '250px' }}
              padding={{ md: '10px', base: '8px' }}
              shadow={'xl'}
              borderRadius={'lg'}
              bg={'#F6F6F6'}
            >
              <Image
                src={WEB_CSR}
                w={{
                  xl: '650px',
                  lg: '500px',
                  md: '400px',
                  sm: '350px',
                  base: '180px',
                }}
                h={{ lg: '410px', md: '360px', sm: '230px', base: '230px' }}
                objectFit={'contain'}
                marginLeft={1}
              ></Image>
              <VStack w={{ base: '90%' }} align={'left'} paddingRight={18}>
                <Text
                  fontSize={{
                    xl: '26px',
                    lg: '21px',
                    md: '17px',
                    base: '9px',
                  }}
                  as="b"
                  marginBottom={2}
                >
                  {BANNER_TITLE}
                </Text>

                <Text
                  noOfLines={10}
                  fontSize={{
                    lg: '16px',
                    md: '12px',
                    base: '8px',
                  }}
                >
                  {BANNER_DESC}
                </Text>

                <Center>
                  <Stack
                    w={'70%'}
                    marginTop={{ lg: '25px', md: '20px', sm: '10px' }}
                  >
                    <CustomButton
                      text={'SELENGKAPNYA'}
                      variant={'red'}
                      icon={'none'}
                      w={'100%'}
                      wMobile="100px"
                      
                    />
                  </Stack>
                </Center>
              </VStack>
            </HStack>
          </Box>
        </Center> */}



   
      {newProductData.map((content, index) => (
        <Center>
          <Box
            key={index}
            marginX={{ lg: '110px',md:'35',sm:'10px',base: '10px', }}
            paddingBottom={{ lg: '50px', md: '40px',sm:'20px', base: '20px', }}
          >
            <HStack
              w={{
                xl: '1200px',
                lg: '900px',
                md: '800px',
                sm: '400px',
                base: '360px',
              }}
              h={{ lg: '470px', md: '430px', base: '250px' }}
              padding={{ md: '10px', base: '8px' }}
              shadow={'xl'}
              borderRadius={'lg'}
              bg={'#F6F6F6'}
            >
              <Image
                src={content.icon}
                w={{
                  xl: '650px',
                  lg: '500px',
                  md: '400px',
                  sm: '350px',
                  base: '180px',
                }}
                // marginTop={{
                //   xl: '10px',
                //   lg: '10px',
                //   md: '10px',
                //  sm:'5px',
                //  base:'5px'
                // }}
                h={{ lg: '410px', md: '360px', sm: '230px', base: '230px' }}
                objectFit={'contain'}
                marginLeft={1}
                ></Image>
                <VStack w={{ base: '90%' }} align={'left'} paddingRight={18}>
                <Text
                  fontSize={{
                    xl: '26px',
                    lg: '21px',
                    md: '17px',
                    base: '9px',
                  }}
                  as="b"
                  marginBottom={2}
                >
                  {content.title}
                </Text>

                <Text
                  noOfLines={10}
                  fontSize={{
                    lg: '16px',
                    md: '12px',
                    base: '8px',
                  }}
                >
                  {content.content}
                </Text>

                <Center>
                  <Stack
                    w={'70%'}
                    marginTop={{ lg: '25px', md: '20px', sm: '10px' }}
                  >
                    <CustomButton
                      text={'SELENGKAPNYA'}
                      variant={'red'}
                      icon={'none'}
                      w={'100%'}
                      wMobile="100px"
                      onClick={() => {
                        if (content.linkTo.startsWith('http')) {
                          window.location.href = content.linkTo; // External URL
                        } else {
                          navigate(content.linkTo); // Internal route
                        }
                      }}
                    />
                  </Stack>
                </Center>
              </VStack>
            </HStack>
          </Box>
        </Center>
      ))}
      
      </Slider>

  );
};

export default NewProduct;
